import React from 'react'
import { Container, Form } from 'react-bootstrap'

export default function Slider() {
    return (
        <Container>
            <Form></Form>
        </Container>
    )
}
