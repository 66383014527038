let xAxisData = [
    {
        "name": 'فروردین',
        "user": 4970
    },
    {
        "name": 'اردیبهشت',
        "user": 3400
    },
    {
        "name": 'خرداد',
        "user": 6444
    },
    {
        "name": 'تیر',
        "user": 10141
    },
    {
        "name": 'شهریور',
        "user": 5874
    },
    {
        "name": 'مهر ',
        "user": 6767
    },
    {
        "name": 'آبان',
        "user": 4242
    },
    {
        "name": 'آذر',
        "user": 14444
    },
    {
        "name": 'دی',
        "user": 15476
    },
    {
        "name": 'بهمن',
        "user": 14347
    },
    {
        "name": 'اسفند',
        "user": 9999
    },

]

export {xAxisData}