import React from 'react'
import Title_Admin from '../TitleAdmin/TitleAdmin'

export default function IMDB() {
    return (
        <div>
            <Title_Admin Title={'مدیریت API IMDB  '}></Title_Admin>

        </div>
    )
}
