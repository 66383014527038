import React from 'react';
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    CartesianGrid,
    Tooltip
} from 'recharts'
import './Chart.css'
export default function Chart({title, data, dataKey, grid}) {
  return (
      <div className="chart">
          <h5 style={{color:'#FFF',marginRight : '10px'}} className='chartTitle'>{title}</h5>
          <ResponsiveContainer width="100%" aspect={4}>
              <LineChart data={data}>
                  <XAxis dataKey="name" stroke='#5550bd' />
                  <Line dataKey={dataKey} stroke='#5550bd' />
                  <Tooltip />
                  {/* {grid && <CartesianGrid stroke='#e0dfdf' strokeDasharray="10" />} */}
              </LineChart>
          </ResponsiveContainer>
      </div>
  )
}
